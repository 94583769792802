* {
    font-family: 'Assistant';
    color: #2d4662
}

body, html {
    background-color: #e0e0e1;
    height: 100%;
    padding: 0;
    margin: 0;
}

#root {
    height: 100%;
}

.wrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    -ms-flex-direction: column;
}

.main-container {
    width: 60%;
    margin: 1rem auto;
    background-color: #e9e9ea;
    box-shadow: 3px 3px 3px #2d4662;
    flex: 1;
}

.content-container {
    margin: 0.5rem;
}

a {
    text-decoration: none;
    color: #5b5f97;
}

a:visited {
    color: #2d4662;
}

 li a:hover {
    text-decoration: underline;
}

.main-container h1 {
    padding-top: 0.8rem
}

@media screen and (min-width: 1260px) and (max-width: 1780px) {
    .main-container {
        width: 70%;
    }
}

@media screen and (min-width: 780px) and (max-width: 1260px) {
    .main-container {
        width: 80%;
    }
}

@media screen and (min-width: 480px) and (max-width: 780px) {
    .main-container {
        width: 85%;
    }
}

@media screen and (max-width: 600px) {
    .main-container {
        width: 90%;
    }
    .main-container h1 {
        padding-top: 0;
        font-size: 1.5rem;
    }

    .navbar h1 {
        font-size: 1rem;
    }

    .footer .icon {
        display: none;
    }

}

@media screen and (min-width: 400px) and (max-width: 600px) {
    .footer .created-by {
        font-size: 0.85rem;
    } 

    .footer a {
        font-size: 0.85rem;
    }
}

@media screen and (min-width: 365px) and (max-width: 400px) {
    .footer .created-by {
        font-size: 0.8rem;
    }

    .footer a {
        font-size: 0.8rem;
    }
}

@media screen and (min-width: 340px) and (max-width: 365px) {
    .footer .created-by {
        font-size: 0.7rem;
    }

    .footer a {
        font-size: 0.7rem;
    }
}

@media screen and (max-width: 340px) {
    .footer .created-by {
        font-size: 0.6rem;
    }

    .footer a {
        font-size: 0.6rem;
    }  
}

/* Navbar */
.navbar {
    background-color: #e0e0e1;
    color: #5d5e60;
    height: 10%;
}

.navbar .flex {
    justify-content: space-between;
}

.navbar ul {
    display: flex;
    list-style-type: none;
}

.navbar li {
    padding: 0 0.5rem;
}
/* Footer */
.footer {
    background-color: #e0e0e1;
    color: #2d4662;
    height: 5rem;
    padding-top: 1rem;
}

.footer .flex {
    justify-content: space-between;
}

.footer ul {
    display: flex;
    list-style-type: none;
}

.footer li {
    padding: 0 0.5rem;
}

.icon {
    padding: 0 0.5rem;
}