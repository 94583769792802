.imageDisplayContainer {
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    border: 3px solid #2d4662;
}

.imageDisplayImage {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}