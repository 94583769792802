.container {
    max-width: 1800px;
    margin: 0 auto;
    padding: 0 40px;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}
