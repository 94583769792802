.content-card {
    display: flex;
    flex-direction: row;
    border-radius: 4px;
    margin: 1rem 0.5rem;
    background-color: #efeff0;
    box-shadow: 2px 2px 2px #2d4662;
}

.image {
    padding: 1rem 0;
}

.left-image {
    padding-left: 1rem;
}

.right-image {
    padding-right: 1rem;
}

.inner-text {
    padding-right: 1rem;
    padding-left: 1rem;
    display: flex;
}

@media screen and (max-width: 780px) {
    .content-card {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .image {
        padding: 1rem 0 0 0;
    }

    /* Image goes on top for smaller displays */

    .image {
        order: 1;
    }

    .inner-text {
        order: 2;
    }
}